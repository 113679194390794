import "./adminNavbar.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect, useCallback } from "react";
import { DarkModeContext } from "../../../context/darkModeContext";
import { AuthContext } from "../../../context/authContext";
import { useNavigate, useLocation } from "react-router-dom";
// import FriendItem from "../../pages/friendsListing/FriendItem";
import { debounce } from 'lodash';
import { Modal } from "antd";
import { makeRequest } from "../../../axios";
import logo_symbol from "../../../assets/c1.png";
import { yellow } from "@mui/material/colors";
import logoutImg from '../../../assets/logout.png'
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from "@mui/material";

const AdminNavbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser, logout } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const navigate = useNavigate();  
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Debounced search function
  const fetchSearchResults = useCallback(debounce(async (query) => {
    try {
      const response = await makeRequest.get(`/ssakti/users/user/searchUser/${query}`);
      setSearchResults(response.data.searchedData);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300), []);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.length > 0) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchResults([]);
    }
  }, [searchInput]);

  const handleItemClick = () => {
    setIsOpen(false); 
  };

  return (
    <div className={`adminNavbar ${darkMode ? 'dark' : 'light'}`} style={{backgroundColor:"#87a2ff"}}>
      <div className="left">
        <Link to="/" style={{ textDecoration: "none" }}>
        <img src={logo_symbol} style={{ width:"50px",height:"50px" }} alt="Logo" />
        </Link>
        {/* <Link to='/'>
        <HomeOutlinedIcon
            sx={{ color: darkMode == true ? "#fff" : '#000' }}
            aria-label="Home"
          />
        </Link>
        {darkMode ? (
          <WbSunnyOutlinedIcon sx={{color:yellow[700]}} onClick={toggle} />
        ) : (
          <DarkModeOutlinedIcon onClick={toggle} />
        )} */}
        {/* <div className="search">
          <SearchOutlinedIcon />
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchInputChange}
            onFocus={() => setIsSearchResultVisible(true)}
            onBlur={() => setTimeout(() => setIsSearchResultVisible(false), 100)}  // Use timeout to avoid quick blurring issues
          />
          {isSearchResultVisible && (
            <div className="searchResultsContainer">
              {searchResults.map((result) => (
                <FriendItem key={result.id} friend={result} />
              ))}
            </div>
          )}
        </div> */}
      </div>
      <div className="right">
        {/* <button onClick={() => setIsModalVisible(true)}>Logout</button> */}
        <Tooltip title="Logout"><button onClick={() => setIsModalVisible(true)}>
          <img src={logoutImg} width={"30px"}/>
          </button></Tooltip>
        
        {/* <div className="user">
          <Link to={`/profile/${currentUser.UUID}`}>
            <img
              src={currentUser.profilePic}
              alt={currentUser.name}
            />
            <span>{currentUser.name}</span>
          </Link>
        </div> */}
        
        <div className="user">
        <button className="user-profile" onClick={toggleDropdown}>
          <img
            src={currentUser.profilePic}
            alt={currentUser.name}
            className="profile-img"
          />
          <span>{currentUser.name}</span>
        </button>
        <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          <li className={`item ${location.pathname== "/admin/dashboard" ? "active" : ""}`}>
           <Link to="/admin/dashboard" className={`item-link ${location.pathname== "/admin/dashboard" ? "active" : ""}`} onClick={handleItemClick}>
              {/* <HomeOutlinedIcon fontSize="medium"/> */}
              <span>Dashboard</span>
            </Link>
          </li>          
          <li className={`item ${location.pathname.includes("/admin/userManager") ? "active" : ""}`} >
            <Link to="/admin/userManager"  className={`item-link ${location.pathname.includes("/admin/userManager") ? "active" : ""}`} onClick={handleItemClick}>
              {/* <PersonIcon fontSize="medium"/> */}
              <span>Organisation</span>
            </Link>
          </li>
        </ul>
      
      </div>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        okButtonProps={{ style: { backgroundColor: '#ec4040', color: 'white' } }} 
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default AdminNavbar;
