import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./compDetails.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileTabsComp from "./ProfileTabsComp"
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { notification } from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Calendar from "../../assets/schedule.png"
import Watch from "../../assets/clock.png"

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function CompDetails() {
  const params = useParams();
  // console.log(params)
  const [selectedTab, setSelectedTab] = useState("Posts");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  const [compAbout,setCompAbout] = useState({})
  // const [groupMembers,setgroupMembers] = useState([])
  // const [pendingFriends,setPendingFriends] = useState([])
  // const [posts, setPosts] = useState([]);
  const [isMember,setIsMember] = useState(false)

  const navigate = useNavigate();

  const getCompDetails=async()=>{
    try{
      const response = await makeRequest.get(`/users/competition/get/${params.compId}`);
      // console.log("thi  is comp",response.data.details[0].imageUrl)
      setCompAbout(response.data.details[0])
    } 
    catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    getCompDetails();
  },[])


  // const getGroupDetails = async ({ pageParam = 0 }) => {
  //   try {
  //     const response = await makeRequest.get(`/users/group/getGroupDetails/${currentUser.id}/${params.id}`, {
  //       params: { page: pageParam, size: 2

  //        }, 
  //     });

  //     if (response.status === 204) {
  //       return {
  //         postDetails: [],
  //         currentGroupMembers: [],
  //         groupAbout: {},
  //         hasNextPage: false,
  //         nextPageNo: undefined,
  //       };
  //     }

  //     const { postDetails, currentGroupMembers, groupAbout, hasNextPage, nextPageNo, pendingFriends } = response.data;
  //     return { postDetails, currentGroupMembers, groupAbout, hasNextPage, nextPageNo , pendingFriends };
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //     throw error;
  //   }
  // };

  // const { data, fetchNextPage, hasNextPage, isLoading, isFetching, status } = useInfiniteQuery(
  //   ['groupDetails'],
  //   getGroupDetails,
  //   {
  //     getNextPageParam: (lastPage) => lastPage.hasNextPage ? lastPage.nextPageNo : undefined,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const posts = data?.pages?.flatMap(page => page.postDetails || []);
  // const groupAbout = data?.pages[0]?.groupAbout || {};
  // const groupMembers = data?.pages[0]?.currentGroupMembers || [];
  // const pendingFriends = data?.pages[0]?.pendingFriends || [];

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100 || isFetching) return;
  //     if (hasNextPage) fetchNextPage();
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [fetchNextPage, hasNextPage, isFetching]);


  // AWS.config.update({
  //   accessKeyId: awsAccessKeyId,
  //   secretAccessKey: awsSecretAccessKey,
  //   region: awsRegion,
  // });

  // const s3 = new AWS.S3();
  // const queryClient = useQueryClient();

  // const mutation = useMutation(
  //   (newPost) => {
  //     return makeRequest.post(
  //       `/users/groups/addPost/${groupAbout.adminId}/${params.id}`,
  //       newPost
  //     );
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["groupDetails"]);
  //     //   queryClient.invalidateQueries(["lastestActivities"]);
  //       notification.success({
  //         message: "Post uploaded successfully",
  //         description: "Your post has been shared with everyone.",
  //       });
  //     },
  //   }
  // );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  


  


  const handleBack = () => {
    navigate(-1);
  };


  const handleModal = (component) => {
    setIsModalVisible(true);
    console.log(component)
    setSeletedModalComponent(component)
  }

  let content;
  switch (seletedModalComponent){
      case "updateComp":
        content = <UpdateGroup compAbout={compAbout} setIsModalVisible={setIsModalVisible}/>
      break;
    case "groupSettings":
      content = <GroupSettings/>
      break;
      case "EditCoverImage":
      content = <EditCoverImage compAbout={compAbout} setIsModalVisible={setIsModalVisible}/>
      break;
    case "participate":
      content = <Participate  getCompDetails={getCompDetails} compAbout={compAbout} params={params} currentUser={currentUser} setIsModalVisible={setIsModalVisible}/>
      break;
    case "nonParticipate":
      content = <NonParticipate  getCompDetails={getCompDetails} compAbout={compAbout} params={params} currentUser={currentUser} setIsModalVisible={setIsModalVisible}/>
      break;  
  }
 

  // Combine all groups of posts
  

  // const handleLoadMore = () => {
  //   if (hasNextPage) {
  //     fetchNextPage();
  //   }
  // };



// if(isLoading){
// return (<p>loading</p>)
// }
  return (
    <div className="compSingle" style={{ height: "90vh", overflow: "scroll" }}>
       <div className="goBack">
        <button onClick={handleBack}>
          <ArrowBackIosIcon className="size-4" />
        </button>
          {/* {
            currentUser.id === groupAbout.adminId && 
            <div onClick={()=>handleModal("EditCoverImage")}>
           <CameraAltIcon style={{fontSize:"2rem"}}/>
          </div>
          } */}
      </div>
      <div>
        <img src={compAbout.imageUrl} alt="comp" />
      </div>
      <div className="detailsGrp">
        <div className="head">
          <img src={compAbout.adminUserProfileImagePath} />
        </div>
        <div className="invite">
          <h1>{compAbout.name}</h1>
          <p>{compAbout.description}</p>
        </div>
        <div className="Tabs">
          <div className="profileTab">
            <div className="tabs">
              <button
                className={selectedTab === "Posts" ? "active" : ""}
                onClick={() => setSelectedTab("Posts")}
              >
                Posts
              </button>
              <button
                className={selectedTab === "AboutComp" ? "active" : ""}
                onClick={() => setSelectedTab("AboutComp")}
              >
                About
              </button>
              {
                compAbout.adminId == currentUser.id &&
                <button
                className={selectedTab == "Participants" ? "active" : ""}
                onClick={() => setSelectedTab("Participants")}
              >
                Participants
              </button>
              }
            </div>
          </div>
          {/* <div className="more">
            <button>
              <MoreVertIcon className="size-3" />
            </button>
          </div> */}
        </div>
        <div className="grpBody">
          <div className="leftBody">
            <ProfileTabsComp compAbout={compAbout} getCompDetails={getCompDetails}  params={params} selectedTab={selectedTab} userId={currentUser.id} data={compAbout}/>
          </div>
          <div className="rightBody">
            {
              // groupAbout.adminId !== currentUser.id ? null : (
              //   <p onClick={()=> handleModal("updateGroup")}>Update Competitions</p>
              // ) 
              }
              
              <Link to={"/createComp"}>Create new Competition</Link>
              {
                compAbout.adminId == currentUser.id || compAbout.participant == true ? null : <p onClick={()=>handleModal("participate")}>Participate</p>
              }
              {/* {
                compAbout.adminId !== currentUser.id  ? null : <p onClick={()=>handleModal("participate")}>Update Competition</p>
              } */}
              {
                compAbout.participant == false ? null : <p onClick={()=>handleModal("nonParticipate")}>Non-Participate</p>
              }
          </div>
          <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
        </div>
      </div>
    </div>
  );
}


export default CompDetails;

export const EditCoverImage=({groupAbout,setIsModalVisible,getGroupDetails})=>{
const queryClient = useQueryClient();
  const [file,setFile] = useState(null);

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const {currentUser} = useContext(AuthContext)

  const S3 = new AWS.S3();

  const handleCoverUpdate=async()=>{
    if(file == null){
      alert("Select your Cover image first!")
    }
      let imageGrp;
      if(file){
        const params = {
          Bucket: S3_BUCKET,
          Key: `groupCover/${file.name}`,
          Body: file,
        };
        const data = await S3.upload(params).promise();
        imageGrp=data.Location
      }
      else{
        imageGrp=groupAbout.groupCoverProfileImagePath
      }
      
      const response = await makeRequest.put(`ssakti/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`, {
        groupName: null,
        groupDescription: null,
        groupCoverProfileImagePath: imageGrp,
      });
      console.log(response);
      if(response){
        notification.success({
          message: "Group's Cover Image updated successfully",
        });
        // navigate(`/groups`)
        setIsModalVisible(false)
        queryClient.invalidateQueries(["groupDetails"])
        
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update group details.",
        });
      }
  }




  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Cover Image</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} type="file" onChange={(e)=>setFile(e.target.files[0])} />
      {
        file && <img src={URL.createObjectURL(file)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleCoverUpdate} >Update Cover Image</button>
    </div>
  )
} 

export const GroupSettings=()=>{
  return (
    <div>
      <h1>Group settings</h1>
    </div>
  )
}

export const AddMembers=({pendingFriends,grp,setIsModalVisible})=>{
  const {currentUser} = useContext(AuthContext)
  const queryClient = useQueryClient();

  const [selectedUser, setSelectedUser] = useState([]);
  const navigate = useNavigate()

  const addToGroup = (userId)=>{
    setSelectedUser((prev)=>{
      if(prev.includes(userId)){
        return prev.filter((Id)=>Id!==userId)
      }
      else{
        return [...prev, userId]
      }
    })
  }

  const addPendingToGroup=async()=>{
    if(selectedUser.length ===0){
      alert("Please select atleast one friend")
      return;
    }
    else{
      const userParams = selectedUser && selectedUser.length > 0 && selectedUser.map(id => `userId=${id}`).join("&")
      console.log(userParams)
      const response = await makeRequest.post(`/users/group/addmemberToGroup/${grp.adminId}/${grp.groupId}?${userParams}`)
      console.log("response",response)
      if(response){
        notification.success({
          message: 'Members added successfully',
          description: "You can also add other members to this group.",
        });
        setSelectedUser([])
        setIsModalVisible(false)
       queryClient.invalidateQueries(['groupDetails'])
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to add members to the group.",
        });
        setIsModalVisible(false)
        setSelectedUser([])
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"30px"}}>Add Members</h1>

      <div className="list" style={{width:"80%",height:"50%",margin:"auto",marginBottom:"20px"}}>
      {
        pendingFriends && pendingFriends.length > 0 ?
        pendingFriends.map((item,index)=>{
          return(
            <div key={index} className='friend' style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
                <img src={item.userProfileImagePath} alt={item.userFirstName} width="30px" height="30px" style={{borderRadius:"50px"}} />
                <p>{item.userFirstName} {item.userLastName}</p>
                </div>
                <input type='checkbox'  checked={selectedUser.includes(item.userId)} onClick={()=>addToGroup(item.userId)}/>
              </div>
          )
        })
        :
        <div style={{marginTop:"20px"}}>No pending friends to add</div>
      }
      </div>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={addPendingToGroup}>Add To Organisation</button>
    </div>
  )
}

export const UpdateGroup=({groupAbout,setIsModalVisible})=>{
  // console.log("this is a group",groupAbout)

  const [newGroupName, setNewGroupName] = useState(groupAbout.groupName)
  const [newGroupDescription, setNewGroupDescription] = useState(groupAbout.groupDescription)
  const [newFile,setNewFile] = useState()
  const {currentUser} = useContext(AuthContext)
  const queryClient = useQueryClient();

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const S3 = new AWS.S3();

  const handleGroupUpdate=async()=>{
    if(!newGroupName ||!newGroupDescription){
      alert("Please fill all the fields")
      return;
    }
    else{
      let imageGrp;
      if(newFile){
        const params = {
          Bucket: S3_BUCKET,
          Key: `groupCover/${newFile.name}`,
          Body: newFile,
        };
        const data = await S3.upload(params).promise();
        imageGrp=data.Location
      }
      else{
        imageGrp=groupAbout.groupCoverProfileImagePath
      }
      
      
      const response = await makeRequest.put(`/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`, {
        groupName: newGroupName,
        groupDescription: newGroupDescription,
        groupCoverProfileImagePath: imageGrp,
      });
      console.log(response);
      if(response){
        notification.success({
          message: 'Group updated successfully',
        });
        // navigate(`/groups`)
        setIsModalVisible(false)
        queryClient.invalidateQueries(["groupDetails"])
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update group details.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Organisation</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Organisation Name</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupName" value={newGroupName} onChange={(e)=>setNewGroupName(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Organisation Description</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupDescription" value={newGroupDescription} onChange={(e)=>setNewGroupDescription(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} placeholder={groupAbout.groupDescription} type="file" onChange={(e)=>setNewFile(e.target.files[0])} />
      {
        newFile && <img src={URL.createObjectURL(newFile)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div>

      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleGroupUpdate} >Update</button>
    </div>
  )
}

export const Participate=({compAbout,getCompDetails,currentUser,params,setIsModalVisible})=>{
  console.log(params,"from exit")
  console.log(currentUser)
  const navigate = useNavigate()

  const participateInComp=async()=>{
    try{
      const response = await makeRequest.post(`/users/competition/enter/${params.compId}`)
      console.log(response)
      if(response){
        notification.success({
          message: 'Competition participated successfully',
        });
        getCompDetails();
        setIsModalVisible(false);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to participate in Competition.",
        });
      }
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to participate in Competition.",
      });
    }
  }

  return(
    <div>
      <h1 style={{margin:"20px"}}>Participate</h1>
      <p style={{fontSize:"0.8rem",marginBottom:"10px"}}>Do you want to participate in this Competition?</p>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={participateInComp}>Participate</button>
    </div>
  )
}

export const NonParticipate=({compAbout,getCompDetails,currentUser,params,setIsModalVisible})=>{


  const nonParticipateInComp=async()=>{
    try{
      const response = await makeRequest.delete(`/users/competition/exitCompetition/${currentUser.id}/${params.compId}`)
      console.log(response)
      if(response){
        notification.success({
          message: 'Competition Non-participated successfully',
        });
        getCompDetails();
        setIsModalVisible(false);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to Non-Participate in Competition.",
        });
      }
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to Non-Participate in Competition.",
      });
    }
  }

  return(
    <div>
      <h1 style={{margin:"20px"}}>Exit</h1>
      <p style={{fontSize:"0.8rem",marginBottom:"10px"}}>Do you want to exit from this Competition?</p>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={nonParticipateInComp}>Exit</button>
    </div>
  )
}