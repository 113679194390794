
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./register.scss";
import axios from "axios";
import { message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../assets/c1.png';
import { debounce } from "@mui/material";
import { makeRequest } from "../../axios";
import { CloseOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const OrgName = ({ name, selectOrganization, closeSearchResults }) => {
  const handleClick = () => {
    selectOrganization(name.orgName); // Set the selected organization name
    closeSearchResults(); // Close the search results
  };

  return <p style={{cursor:"pointer",padding:'10px'}} onClick={handleClick}>{name.orgName}</p>; // Display the organization name
};

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [inputs, setInputs] = useState({
    firstName: "",
    email: "",
    password: "",
    lastName: "",
    dob: "",
    address: "",
    district: "",
    taluka:'',
    gender: "",
    mobNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [err, setErr] = useState(null);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const isMobile = window.innerWidth <= 768;
  const [districts, setDistricts] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [regText, setRegText] = useState(false);
  const [talukas,setTalukas] = useState([])

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(apiUrl + "/users/district/getAllDistricts");
        setDistricts(response.data.getAllDistrictData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDistricts();
  }, [apiUrl]);

  useEffect(() => {
    if (inputs.district) {
      const getTaluka = async () => {
        try {
          const response = await axios.get(`${apiUrl}/users/district/getTalukas/${inputs.district}`);
          console.log("response of tal",response.data.getAllTalukasData)
          setTalukas(response.data.getAllTalukasData);
        } catch (err) {
          console.error(err);
        }
      };
      getTaluka();
    }
  }, [inputs.district, apiUrl]);

  
  let successMobile = {
    marginTop: '10vh',
    height: "200px",
  };

  let successWeb = {
    marginTop: '10vh',
    marginRight: '50vw',
    height: "200px",
  };

  const validate = () => {
    const newErrors = {};

    if (!inputs.firstName) newErrors.firstName = "First name is required";
    if (!inputs.lastName) newErrors.lastName = "Last name is required";
    if (!inputs.password) {
      newErrors.password = "Password is required";
    } else if (inputs.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (!inputs.dob) newErrors.dob = "Date of birth is required";
    if (!inputs.address) newErrors.address = "Address is required";
    if (!inputs.district) newErrors.district = "District is required";
    if (!inputs.taluka) newErrors.taluka = "Taluka is required";
    if (!captchaToken) newErrors.captcha = "Please complete the CAPTCHA";
    if (!inputs.email && !inputs.mobNumber) {
      newErrors.email = "Either Email or Mobile Number is required";
      newErrors.mobNumber = "Either Email or Mobile Number is required";
    } else {
      if (inputs.email && !/\S+@\S+\.\S+/.test(inputs.email)) {
        newErrors.email = "Email is invalid";
      }
      if (inputs.mobNumber && !/^\d{10}$/.test(inputs.mobNumber)) {
        newErrors.mobNumber = "Mobile number is invalid";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: "" })); // Clear errors as user types
  };

  // const handleClick = async (e) => {
  //   e.preventDefault();
  //   console.log(inputs);
  //   // Implement registration logic here
  // };

  const handleClick = async (e) => {
    e.preventDefault();
    
    if (!validate()) return;
    
    try {
      setRegText(true)
      await axios.post(apiUrl + "/users/user/userRegister", {
        userFirstName: inputs.firstName,
        userLastName: inputs.lastName,
        userEmail: inputs.email,
        userDateOfBirth: inputs.dob,
        userAddress: inputs.address,
        userProfileImagePath: null,
        userCoverProfileImagePath:null,
        userPassword: inputs.password,
        districtId: inputs.district,
        userGender:inputs.gender,
        userMobileNumber: inputs.mobNumber,
        userRole:"ROLE_USER",
        talukaId: inputs.taluka
      });
      console.log("inputs are,",inputs)
      setSuccess(true);
      messageApi.open({
        type: 'success',
        content: <RegistrationSuccess />,
        className: 'custom-class',
        style: isMobile ? successMobile : successWeb,
      });
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setErr(err.response.data.message);
      setRegText(false)
    }
    setRegText(false)
  };

 

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);  // Update captcha token state
  };

  const RegistrationSuccess = () => {
    return (
      <div className="success">
        <h4>Registration Successful</h4>
        <p>You will be redirected to the login page in 5 seconds.</p>
      </div>
    );
  };

  // const fetchSearchResults = useCallback(
  //   debounce(async (query) => {
  //     try {
  //       const response = await makeRequest.get(`/sup-admin/getOrg?orgName=${query}`);
  //       setSearchResults(response.data.organisationDetails);
  //     } catch (error) {
  //       console.error("Error fetching search results:", error);
  //     }
  //   }, 300),
  //   []
  // );

  // const handleSearchInputChange = (e) => {
  //   const value = e.target.value;
  //   setSearchInput(value);
  //   setInputs((prev) => ({ ...prev, organisation: value })); // Update the organisation state
  //   if (value.length > 0) {
  //     fetchSearchResults(value);
  //   } else {
  //     setSearchResults([]);
  //   }
  // };
  

  // useEffect(() => {
  //   if (searchInput.length === 0) {
  //     setSearchResults([]);
  //   }
  // }, [searchInput]);




  return (
    <div className="register">
      {contextHolder}
      <div className="card">
        {/* <div className="left">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <div className="image"></div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="right">
          <div className="head">
            <img src={logo} alt="" width={"150px"} height={"150px"} />
            <p>Register</p>
          </div>
          <form>
            <div className="input-wrapper">
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                />
                <span className="error">{errors.firstName}</span>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                />
                <span className="error">{errors.lastName}</span>
              </div>
            </div>
            <div className="input-wrapper">
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                />
                <span className="error">{errors.email}</span>
              </div>
              <Tooltip title="Date Of Birth">
              <div>
                <input
                  type="date"
                  placeholder="Date of Birth"
                  name="dob"
                  onChange={handleChange}
                />
                <span className="error">{errors.dob}</span>
              </div>
              </Tooltip>              
            </div>
            <div className="input-wrapper">
              <div>
                <select
                className="tSi"
                  name="district"
                  value={inputs.district}
                  onChange={handleChange}
                >
                  <option value="" style={{color:'red'}}>Select District</option>                  
                  {
                    districts && districts
                      .filter(item => item.districtId !== 1)
                      .map((item, index) => (
                        <option key={index} value={item.districtId}>{item.districtName}</option>
                      ))
                  }
                </select>
                <span className="error">{errors.district}</span>
              </div>
              <div>
                <select
                  name="taluka"
                  value={inputs.taluka}
                  onChange={handleChange}
                >
                  <option value="">Select Taluka</option>
                  {talukas && talukas.map((tal) => (
                    <option key={tal.talukaId} value={tal.talukaId}>
                      {tal.talukaName}
                    </option>
                  ))}
                </select>
                <span className="error">{errors.taluka}</span>
              </div>
              
            </div>
            <div className="input-wrapper">
              
              <div>
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                />
                <span className="error">{errors.address}</span>
              </div>
              <div>
                <select name="gender" value={inputs.gender} onChange={handleChange}>
                  <option value=''>Select gender</option>
                  <option value={"MALE"}>Male</option>
                  <option value={"FEMALE"}> Female</option>
                </select>
              </div>
            </div>
            <div className="input-wrapper">             
              <div>
                <input
                  type="text"
                  placeholder="Mobile number"
                  name="mobNumber"
                  onChange={handleChange}
                />
                <span className="error">{errors.mobNumber}</span>
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                />
                <span className="error">{errors.password}</span>
              </div>
            </div>
            <div className="input-wrapper">
            
            <div>
              <ReCAPTCHA
                sitekey="6Lf3dTUqAAAAALp5SMv55nhNfdVb7Pkwk_O7w45i"
                onChange={handleCaptchaChange}
                />
              <span className="error">{errors.captcha}</span>
            </div>
                </div>
            <span className="error">{err && err}</span>
            {success && "Registration is successful. Redirecting to login..."}
            <button onClick={handleClick}>{regText ? (
              <div className="loader">
                <p>Registering </p>
                <div className="spinner"></div>
              </div>
            ) : "Register"}</button>
          </form>
          <p>Already have an account? <Link className="link-signup" to="/login">Login</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Register;


// const OrgName = ({ name, selectOrganization, closeSearchResults }) => {
//   const handleClick = () => {
//     selectOrganization(name.orgName); // Call the function to set the organization name
//     closeSearchResults(); // Close the search results
//   };

//   return (
//     <p onClick={handleClick}>{name.orgName}</p> // Display the organization name
//   );
// };
