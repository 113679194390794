import React from 'react'
import './about.scss';

function About({data}) {
    // console.log("data from about:",data)
  return (
    <div className='about'>
      <h1>About</h1>
      <hr/>
      <div className="user-details">
        <div className="item">
            <h4>NAME</h4>
            <p>{data.userFirstName+" "+data.userLastName}</p>
        </div>
        <div className="item">
            <h4>EMAIL</h4>
            <p>{data.userEmail}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>D.O.B</h4>
            <p>{data.userBirthDate}</p>
        </div>
        <div className="item">
            <h4>LOCATION</h4>
            <p>{data.userLocation}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>GENDER</h4>
            <p>{data.userGender}</p>
        </div>
        <div className="item">
            <h4>HOBBIES</h4>
            <p>{data.userHobbies}</p>
        </div>
      </div>
    </div>
  )
}

export default About
