// import { useState } from "react";
// import { makeRequest } from "../../axios";
// import "./update.scss";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import AWS from 'aws-sdk';
// import { Password } from "@mui/icons-material";
// import { useContext } from "react";
// import { AuthContext } from "../../context/authContext";
// import { notification } from 'antd';

// const awsRegion = process.env.REACT_APP_AWS_REGION;
// const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
// const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

// const Update = ({ setOpenUpdate, user }) => {
//   const [cover, setCover] = useState(null);
//   const [profile, setProfile] = useState(null);
//   const [texts, setTexts] = useState({
//     password: user.password,
//     address: user.userLocation,
//   });

//   const { currentUser,setCurrentUser } = useContext(AuthContext);



//   const S3_BUCKET = 'streesakti';

//   AWS.config.update({
//     accessKeyId: awsAccessKeyId,
//     secretAccessKey: awsSecretAccessKey,
//     region: awsRegion
//   });

//   const s3 = new AWS.S3();


//   // const upload = async (file) => {
//   //   console.log(file)
//   //   try {
//   //     const formData = new FormData();
//   //     formData.append("file", file);
//   //     const res = await makeRequest.post("/upload", formData);
//   //     return res.data;
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // };

//   const handleChange = (e) => {
//     setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
//   };

//   const queryClient = useQueryClient();

//   const mutation = useMutation(
//     (user) => {
//       return makeRequest.put(`/users/user/updateUser/${currentUser.id}`, user);
//     },
//     {
//       onSuccess: (data,variables) => {
//         // Invalidate and refetch
//         queryClient.invalidateQueries(["user"]);
//         queryClient.invalidateQueries(["lastestActivities"]);
//         setCurrentUser((prev)=>{
//           return {...prev,profilePic:variables.userProfileImagePath}})
//         console.log(data)
//         notification.success({
//           message: 'Profile updated successfully',
//         });
//       },
//     }
//   );

//   const handleClick = async (e) => {
//     e.preventDefault();

//     // Check if any field is empty
//     // if (!texts.password || !texts.address) {
//     //   notification.error({
//     //     message: 'Error',
//     //     description: 'All fields are required!',
//     //   });
//     //   return; // Exit the function if validation fails
//     // }

//     let coverUrl;
//     let profileUrl;

//     if (cover) {
//       const params = {
//         Bucket: S3_BUCKET,
//         Key: `posts/${cover.name}`,
//         Body: cover,
//       };
//       try {
//         const data = await s3.upload(params).promise();
//         console.log("File uploaded successfully. File URL:", data.Location);

//         coverUrl = data.Location;
//       } catch (error) {
//         console.error("Error uploading file:", error);
//       }
//     } else {
//       coverUrl = user.userCoverProfileImage ? user.userCoverProfileImage : "";
//     }

//     if (profile) {
//       const params = {
//         Bucket: S3_BUCKET,
//         Key: `posts/${profile.name}`,
//         Body: profile,
//       };
//       try {
//         const data = await s3.upload(params).promise();
//         console.log("File uploaded successfully. File URL:", data.Location);

//         profileUrl = data.Location;
//       } catch (error) {
//         console.error("Error uploading file:", error);
//       }
//     } else {
//       profileUrl = user.userProfileImage ? user.userProfileImage : "";
//     }

//     mutation.mutate({
//       userDateOfBirth: "",
//       userAddress: texts.address,
//       userProfileImagePath: profileUrl,
//       userCoverProfileImagePath: coverUrl,
//       userPassword: texts.password,
//     });

//     setOpenUpdate(false);
//     setCover(null);
//     setProfile(null);
//   };

//   return (
//     <div className="update">
//       <div className="wrapper">
//         <h1>Update Your Profile</h1>
//         <form>
//           <div className="files">
//             <label htmlFor="cover">
//               <span>Cover Picture</span>
//               <div className="imgContainer">
//                 <img
//                   src={
//                     cover
//                       ? URL.createObjectURL(cover)
//                       : user.userCoverProfileImage
//                   }
//                   alt=""
//                 />
//                 <CloudUploadIcon className="icon" />
//               </div>
//             </label>
//             <input
//               type="file"
//               id="cover"
//               style={{ display: "none" }}
//               onChange={(e) => setCover(e.target.files[0])}
//             />
//             <label htmlFor="profile">
//               <span>Profile Picture</span>
//               <div className="imgContainer">
//                 <img
//                   src={
//                     profile
//                       ? URL.createObjectURL(profile)
//                       : user.userProfileImage
//                   }
//                   alt=""
//                 />
//                 <CloudUploadIcon className="icon" />
//               </div>
//             </label>
//             <input
//               type="file"
//               id="profile"
//               style={{ display: "none" }}
//               onChange={(e) => setProfile(e.target.files[0])}
//             />
//           </div>
//           <div>
//             <label>Password</label>
//             <input
//               type="text"
//               value={texts.password}
//               name="password"
//               onChange={handleChange}
//               placeholder="update password / enter old password"
//             />
//           </div>

//           <div>
//             <label>Address</label>
//             <input
//               type="text"
//               name="address"
//               value={texts.address}
//               onChange={handleChange}
//             />
//           </div>
//           <button onClick={handleClick}>Update</button>
//         </form>
//         <button className="close" onClick={() => setOpenUpdate(false)}>
//           close
//         </button>
//       </div>
//     </div>

//   );
// }

// export default Update;


import { useState } from "react";
import { makeRequest } from "../../axios";
import "./update.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AWS from 'aws-sdk';
import { useContext, useCallback } from "react";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Update = ({ setOpenUpdate, user }) => {
  const [cover, setCover] = useState(null);
  const [profile, setProfile] = useState(null);
  const [updatePassword, setUpdatePassword] = useState(false); // Toggle for updating password
  const [texts, setTexts] = useState({
    firstName: user.userFirstName,
    lastName: user.userLastName,
    dob: user.userBirthDate,
    address: user.userLocation,
    organisationName: user.organisationName,
    password: '', // Password initially empty
  });
  // const [searchInput, setSearchInput] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const params = useParams();

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const S3_BUCKET = 'streesakti';

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();
 
  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      try {
        const response = await makeRequest.get(
          `sup-admin/getOrg?orgName=${query}`
        );
         setSearchResults(response.data.organisationDetails);
        console.log(response.data.organisationDetails)
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }, 300),
    []
  );

  const handleSearchInputChange = (e) => {
    console.log(e)
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const value = e.target.value;
      // const value = "s";

    // setSearchInput(value);
    if (value.length > 0) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };
  const handleSelectSuggestion = (selectedOrgName) => {
    // setTexts({ organisationName: selectedOrgName });  
    setTexts((prev) => ({
      ...prev,  
      organisationName: selectedOrgName,  
    }));   
    setSearchResults([]); 
  };

  const handleChange = (e) => {
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (user) => {
      return makeRequest.put(`/users/user/updateUser/${currentUser.id}`, user,{
        headers: {
          "Content-Type": "multipart/form-data",  // Manually setting the Content-Type header
        },
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        // queryClient.refetchQueries(["user"]);
        // console.log(variables)
        // setCurrentUser((user) => {
        //   // console.log(prev)
        //   console.log(user)
          
        //   return { ...user, profilePic: variables.userProfileImagePath };
        // });        
        
          // function updateOrganisationName(newOrganisationName) {            
          //   const user = JSON.parse(localStorage.getItem('user'));
          
          //   if (user) {
          //     user.organisationName = newOrganisationName;              
          //     localStorage.setItem('user', JSON.stringify(user));
              
          //   } else {
          //     console.log('No user data found in localStorage');
          //   }
          // }
          
          // updateOrganisationName(texts.organisationName)

          async function userUpdate(){
            const user = JSON.parse(localStorage.getItem('user'));
            try {
              const res = await makeRequest.get(`/users/user/userAbout/${params.id}`)

              if(res.data){
                setCurrentUser((prevUser) => {                               
                    return { ...prevUser, 
                      username: res.data.userFirstName,
                      profilePic: res.data.userProfileImage,
                      name: res.data.userFirstName ,
                      organisationName:res.data.organisationName,
                      city: res.data.userLocation,
                    };
                  });
              }

              if (user) {
                  user.organisationName = res.data.organisationName;
                  user.profilePic = res.data.userProfileImage ;
                  user.username = res.data.userFirstName;
                  user.name = res.data.userFirstName ;
                  user.city = res.data.userLocation;

                  localStorage.setItem('user', JSON.stringify(user));
                  
                } else{
                  console.log('No user data found in localstorage')
                }
              
            } catch (error) {
              console.error("Error While updating current User:", error);
            }

          }
          userUpdate()
        
        //  console.log(variables) 
        //console.log(currentUser)      
        notification.success({
          message: 'Profile updated successfully',
        });
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();  
  
    // Validation for required fields
    if (!texts.firstName || !texts.lastName || !texts.dob || !texts.address || !texts.organisationName) {
      notification.error({
        message: 'Error',
        description: 'First Name, Last Name, Date of Birth,Address and Organisation Name  are required!',
      });
      return; // Exit if validation fails
    }
  
    let coverImageFile=null;
    let profileImageFile=null;
  
    // Handle Cover Image
    if (cover) {
      const imageOptions = {
        maxSizeMB: 0.15, // 150KB
        useWebWorker: true,
      };
  
      try {
        const compressedBlob = await imageCompression(cover, imageOptions);
        coverImageFile = new File([compressedBlob], cover.name, {
          type: compressedBlob.type,
          lastModified: cover.lastModified,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }  
    // else if (user.userCoverProfileImage) {

    //   console.log("in else cover")
    //   try {
    //     const response = await fetch(user.userCoverProfileImagePath);
    //     const blob = await response.blob();
    //     coverImageFile = new File([blob], 'profile_image.jpg', { type: 'image/jpeg' });
    //   } catch (error) {
    //     console.error("Error fetching profile image:", error);
    //   }}
  
    if (profile) {
      const imageOptions = {
        maxSizeMB: 0.15, // 150KB
        useWebWorker: true,
      };
  
      try {
        const compressedBlob = await imageCompression(profile, imageOptions);
        profileImageFile = new File([compressedBlob], profile.name, {
          type: compressedBlob.type,
          lastModified: profile.lastModified,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }  
    // else if (user.userProfileImage) {
    //   console.log("in else profile")
    //   try {
    //     const response = await fetch(user.userProfileImagePath);
    //     const blob = await response.blob();
    //     profileImageFile = new File([blob], 'profile_image.jpg', { type: 'image/jpeg' });
    //   } catch (error) {
    //     console.error("Error fetching profile image:", error);
    //   } }
  
    // Prepare the payload



    const formData = new FormData();
    formData.append("userFirstName", texts.firstName);
    formData.append("userLastName", texts.lastName);
    formData.append("userDateOfBirth", texts.dob);
    formData.append("userAddress", texts.address);
    formData.append("organisationName", texts.organisationName);
    if(profileImageFile){
      formData.append("userProfileImagePath", profileImageFile);
    }
    if(coverImageFile){
       formData.append("userCoverProfileImagePath", coverImageFile);
    }
   
  
    // Add password to payload only if updating
    if (updatePassword) {
      formData.append("userPassword", texts.password);
    }
  
    // Send the data to the backend
    mutation.mutate(formData);
                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    // setOpenUpdate(false);
    setCover(null);
    setProfile(null);
  };
  

  return (
    <div className="update">
      <div className="wrapper">
        <h1>Update Your Profile</h1>
        <form>
          <div className="files">
            <label htmlFor="cover">
              <span>Cover Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    cover
                      ? URL.createObjectURL(cover)
                      : user.userCoverProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="cover"
              style={{ display: "none" }}
              onChange={(e) => setCover(e.target.files[0])}
            />
            <label htmlFor="profile">
              <span>Profile Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    profile
                      ? URL.createObjectURL(profile)
                      : user.userProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="profile"
              style={{ display: "none" }}
              onChange={(e) => setProfile(e.target.files[0])}
            />
          </div>

          {/* First Name Input */}
          <div className="input-wrapper">
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={texts.firstName}
              onChange={handleChange}
              placeholder="Enter first name"
              required
            />
          </div>

          {/* Last Name Input */}
          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={texts.lastName}
              onChange={handleChange}
              placeholder="Enter last name"
              required
            />
          </div>
          </div>

          {/* Date of Birth Input */}
          <div className="input-wrapper">
          <div>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={texts.dob}
              onChange={handleChange}
              required
            />
          </div>

          {/* Address Input */}
          <div>
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={texts.address}
              onChange={handleChange}
              required
            />
          </div>
          {/* Organisation Name */}
          <div>
            <label>Organisation Name</label>
            
                  <input
                    type="text"
                    name="organisationName"
                    value={texts.organisationName}  
                    onChange={handleSearchInputChange}  
                    required
                  />
                  
                  {searchResults.length > 0 && (
                    <select
                      size={searchResults.length>3?3:searchResults.length}  
                      onClick={(e) => handleSelectSuggestion(e.target.value)} 
                      value={texts.organisationName}  
                    >
                      {searchResults.map((result, index) => (
                        <option key={index} value={result.orgName}>
                          {result.orgName}
                        </option>
                      ))}
                    </select>
                  )}
          </div>
          </div>

          {/* Password Toggle */}
          <div className="input-wrapper">
          <div>
            <label id="password-ag">
              <input
                type="checkbox"
                checked={updatePassword}
                onChange={() => setUpdatePassword(!updatePassword)}
              />
              Do you want to update your password?
            </label>
          </div>

          {/* Password Input (visible only if updatePassword is true) */}
          {updatePassword && (
            <div>
              <label>Password</label>
              <input
                type="password"
                value={texts.password}
                name="password"
                onChange={handleChange}
                placeholder="Enter new password"
              />
            </div>
          )}
          </div>

          <button onClick={handleClick}>Update</button>
        </form>
        <button className="close" onClick={() => setOpenUpdate(false)}>
          Close
        </button>
      </div>
    </div>
  );
}

export default Update;