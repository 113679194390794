import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./singleGroup.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileTabsGroup from "./ProfileTabsGroup";
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { message, notification} from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import imageCompression from "browser-image-compression";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function SingleGroup() {
  const params = useParams();
  // console.log(params)
  const [selectedTab, setSelectedTab] = useState("Posts");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  // const [groupAbout,setgroupAbout] = useState({})
  // const [groupMembers,setgroupMembers] = useState([])
  // const [pendingFriends,setPendingFriends] = useState([])
  // const [posts, setPosts] = useState([]);
  const [isMember,setIsMember] = useState(false)

  const navigate = useNavigate();



  const getGroupDetails = async ({ pageParam = 0 }) => {
    try {
      const response = await makeRequest.get(`/users/group/getGroupDetails/${params.id}`, {
        params: { page: pageParam, size: 2

         }, 
      });

      if (response.status === 204) {
        return {
          postDetails: [],
          currentGroupMembers: [],
          groupAbout: {},
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }

      const { postDetails, currentGroupMembers, groupAbout, hasNextPage, nextPageNo, pendingFriends } = response.data;
      return { postDetails, currentGroupMembers, groupAbout, hasNextPage, nextPageNo , pendingFriends };
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, status } = useInfiniteQuery(
    ['groupDetails'],
    getGroupDetails,
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage ? lastPage.nextPageNo : undefined,
      refetchOnWindowFocus: false,
    }
  );

  const posts = data?.pages?.flatMap(page => page.postDetails || []);
  const groupAbout = data?.pages[0]?.groupAbout || {};
  const groupMembers = data?.pages[0]?.currentGroupMembers || [];
  const pendingFriends = data?.pages[0]?.pendingFriends || [];

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100 || isFetching) return;
      if (hasNextPage) fetchNextPage();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);


  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/users/groups/addPost/${groupAbout.adminId}/${params.id}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groupDetails"]);
      //   queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  // const handleClick = async () => {
  //   if (desc.trim() === "") {
  //     setInputNameError("Description is required");
  //     return;
  //   }

  //   let fileToUpload = file;
  //   if (file && file.type === "image/heic") {
  //     try {
  //       const convertedBlob = await heic2any({
  //         blob: file,
  //         toType: "image/jpeg",
  //       });
  //       fileToUpload = new File(
  //         [convertedBlob],
  //         file.name.replace(/\.[^/.]+$/, ".jpg"),
  //         { type: "image/jpeg" }
  //       );
  //     } catch (error) {
  //       console.error("Error converting HEIC to JPEG:", error);
  //       notification.error({
  //         message: "Conversion Failed",
  //         description: `There was an error converting your HEIC image: ${error.message}`,
  //       });
  //       return;
  //     }
  //   }

  //   let postImageUrl = null;
  //   if (file) {
  //     const params = {
  //       Bucket: S3_BUCKET,
  //       Key: `posts/${fileToUpload.name}`,
  //       Body: fileToUpload,
  //     };

  //     try {
  //       const data = await s3.upload(params).promise();
  //       postImageUrl = data.Location;
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //       notification.error({
  //         message: "Upload Failed",
  //         description: `There was an error uploading your file: ${error.message}`,
  //       });
  //       return;
  //     }
  //   }

  //   mutation.mutate({
  //     postName: desc,
  //     postImageUrl: postImageUrl,
  //     postType: file ? fileToUpload.type : null,
  //   });

  //   setFile(null);
  //   setDesc("");
  // };


  


  const handleBack = () => {
    navigate(-1);
  };


  const handleModal = (component) => {
    setIsModalVisible(true);
    setSeletedModalComponent(component)
  }

  let content;
  switch (seletedModalComponent){
    case "addMembers":
      content = <AddMembers pendingFriends={pendingFriends} grp={groupAbout} getGroupDetails={getGroupDetails} setIsModalVisible={setIsModalVisible}/>
      break;
      case "updateGroup":
        content = <UpdateGroup groupAbout={groupAbout} getGroupDetails={getGroupDetails} setIsModalVisible={setIsModalVisible}/>
      break;
    case "groupSettings":
      content = <GroupSettings/>
      break;
      case "EditCoverImage":
      content = <EditCoverImage groupAbout={groupAbout} getGroupDetails={getGroupDetails} setIsModalVisible={setIsModalVisible}/>
      break;
    case "exitGroup":
      content = <ExitGroup groupAbout={groupAbout} currentUser={currentUser}/>
      break;
  }
 

  // Combine all groups of posts
  

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };



if(isLoading){
return (<p>loading</p>)
}
  return (
    <div className="grpSingle" style={{ height: "90vh", overflow: "scroll" }}>
       <div className="goBack">
        <button onClick={handleBack}>
          <ArrowBackIosIcon className="size-4" />
        </button>
          {
            currentUser.id === groupAbout.adminId && 
            <div onClick={()=>handleModal("EditCoverImage")}>
           <CameraAltIcon style={{fontSize:"2rem"}}/>
          </div>
          }
      </div>
      <div>
        <img src={groupAbout.groupCoverProfileImagePath} alt="group" />
      </div>
      <div className="detailsGrp">
        <div className="head">
          <img src={groupAbout.adminUserProfileImagePath} />
        </div>
        <div className="invite">
          <h1>{groupAbout.groupName}</h1>
  
        </div>
        <div className="Tabs">
          <div className="profileTab">
            <div className="tabs">
              <button
                className={selectedTab === "Posts" ? "active" : ""}
                onClick={() => setSelectedTab("Posts")}
              >
                Posts
              </button>
              <button
                className={selectedTab === "Aboutgroup" ? "active" : ""}
                onClick={() => setSelectedTab("AboutGroup")}
              >
                About
              </button>
              <button
                className={selectedTab === "Friends" ? "active" : ""}
                onClick={() => setSelectedTab("Friends")}
              >
                Members
              </button>
            </div>
          </div>
          {/* <div className="more">
            <button>
              <MoreVertIcon className="size-3" />
            </button>
          </div> */}
        </div>

        <div className="grpBody">
          <div className="leftBody">
            <ProfileTabsGroup groupMembers={groupMembers} selectedTab={selectedTab} post={posts} userId={currentUser.id} data={groupAbout}/>
          </div>
          <div className="rightBody">
            {
              groupAbout.adminId !== currentUser.id ? null : (
                <p onClick={()=> handleModal("updateGroup")}>Update Organisation</p>
              ) 
              }
              {
                groupAbout.adminId !== currentUser.id ? null : (
                  <p onClick={()=> handleModal("addMembers")}>Add members</p>
                )
              }
              <Link to={"/createOrganisation"}>Create new organisation</Link>
              {
                groupAbout.adminId === currentUser.id ? null : <p onClick={()=>handleModal("exitGroup")}>Exit Organisation</p>
              }
          </div>
          <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
        </div>
      </div>
    </div>
  );
}


export default SingleGroup;
export const EditCoverImage=({groupAbout,setIsModalVisible,getGroupDetails})=>{
const queryClient = useQueryClient();
  const [file,setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const {currentUser} = useContext(AuthContext)

  const S3 = new AWS.S3();

  const handleCoverUpdate=async()=>{
    if(file == null){
      alert("Select your Cover image first!")
    }
    let fileToUpload = null;
    if (file) {      
      setLoading(true);
      if (file.type.includes("image")) {
        const imageOptions = {
          maxSizeMB: 0.1, // 150KB
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedBlob = await imageCompression(file, imageOptions);

        // Convert the compressed Blob back to a File
        fileToUpload = new File([compressedBlob], file.name, {
          type: compressedBlob.type,
          lastModified: file.lastModified,
        });
      } else {
        message.error("Only images are allowed!");
        return;
      }
    }

    let formdata = new FormData();
    formdata.append("coverImage",fileToUpload)
      
      try {
        const response = await makeRequest.put(`/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`,formdata, { headers: { "Content-Type": "multipart/form-data" } });
      console.log(response);
      if(response){
        notification.success({
          message: "Group's Cover Image updated successfully",
        });
        // navigate(`/groups`)
        setIsModalVisible(false)
        queryClient.invalidateQueries(["groupDetails"])
        
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update group details.",
        });
      }
      } catch (error) {
        notification.error({ message: 'Error', description: error.message || "An unexpected error occurred." });
      }finally {
        setLoading(false);
      }
  }




  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Cover Image</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} type="file" onChange={(e)=>setFile(e.target.files[0])} />
      {
        file && <img src={URL.createObjectURL(file)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleCoverUpdate} disabled={loading}>{loading ? 'Updating...' : 'Update Cover Image'}</button>
    </div>
  )
} 

export const GroupSettings=()=>{
  return (
    <div>
      <h1>Group settings</h1>
    </div>
  )
}

export const AddMembers=({pendingFriends,grp,setIsModalVisible})=>{
  const {currentUser} = useContext(AuthContext)
  const queryClient = useQueryClient();

  const [selectedUser, setSelectedUser] = useState([]);
  const navigate = useNavigate()

  const addToGroup = (userId)=>{
    setSelectedUser((prev)=>{
      if(prev.includes(userId)){
        return prev.filter((Id)=>Id!==userId)
      }
      else{
        return [...prev, userId]
      }
    })
  }

  const addPendingToGroup=async()=>{
    if(selectedUser.length ===0){
      alert("Please select atleast one friend")
      return;
    }
    else{
      const userParams = selectedUser && selectedUser.length > 0 && selectedUser.map(id => `userId=${id}`).join("&")
      console.log(userParams)
      const response = await makeRequest.post(`/users/group/addmemberToGroup/${grp.adminId}/${grp.groupId}?${userParams}`)
      console.log("response",response)
      if(response){
        notification.success({
          message: 'Members added successfully',
          description: "You can also add other members to this group.",
        });
        setSelectedUser([])
        setIsModalVisible(false)
       queryClient.invalidateQueries(['groupDetails'])
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to add members to the group.",
        });
        setIsModalVisible(false)
        setSelectedUser([])
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"30px"}}>Add Members</h1>

      <div className="list" style={{width:"80%",height:"50%",margin:"auto",marginBottom:"20px"}}>
      {
        pendingFriends && pendingFriends.length > 0 ?
        pendingFriends.map((item,index)=>{
          return(
            <div key={index} className='friend' style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
                <img src={item.userProfileImagePath} alt={item.userFirstName} width="30px" height="30px" style={{borderRadius:"50px"}} />
                <p>{item.userFirstName} {item.userLastName}</p>
                </div>
                <input type='checkbox'  checked={selectedUser.includes(item.userId)} onClick={()=>addToGroup(item.userId)}/>
              </div>
          )
        })
        :
        <div style={{marginTop:"20px"}}>No pending friends to add</div>
      }
      </div>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={addPendingToGroup}>Add To Organisation</button>
    </div>
  )
}

export const UpdateGroup=({groupAbout,setIsModalVisible})=>{
  // console.log("this is a group",groupAbout)

  const [newGroupName, setNewGroupName] = useState(groupAbout.groupName)
  const [newGroupDescription, setNewGroupDescription] = useState(groupAbout.groupDescription)
  const [newFile,setNewFile] = useState()
  const {currentUser} = useContext(AuthContext)
  const queryClient = useQueryClient();

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const S3 = new AWS.S3();

  // const handleGroupUpdate=async()=>{
  //   if(!newGroupName ||!newGroupDescription){
  //     alert("Please fill all the fields")
  //     return;
  //   }
  //   else{
  //     let imageGrp;
  //     if(newFile){
  //       const params = {
  //         Bucket: S3_BUCKET,
  //         Key: `groupCover/${newFile.name}`,
  //         Body: newFile,
  //       };
  //       const data = await S3.upload(params).promise();
  //       imageGrp=data.Location
  //     }
  //     else{
  //       imageGrp=groupAbout.groupCoverProfileImagePath
  //     }
      
      
  //     const response = await makeRequest.put(`/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`, {
  //       groupName: newGroupName,
  //       groupDescription: newGroupDescription,
  //       groupCoverProfileImagePath: imageGrp,
  //     });
  //     console.log(response);
  //     if(response){
  //       notification.success({
  //         message: 'Group updated successfully',
  //       });
  //       // navigate(`/groups`)
  //       setIsModalVisible(false)
  //       queryClient.invalidateQueries(["groupDetails"])
  //     }
  //     else{
  //       notification.error({
  //         message: 'Error',
  //         description: "Failed to update group details.",
  //       });
  //     }
  //   }
  // }
  const handleGroupUpdate=async()=>{
  
    if(!newGroupName ||!newGroupDescription){
      alert("Please fill all the fields")
      return;
    }
    else{
      
      const formdata = new FormData();
      formdata.append("name",newGroupName)
      formdata.append("description",newGroupDescription)
      
      const response = await makeRequest.put(`/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`, formdata, { headers: { "Content-Type": "multipart/form-data" } });
      console.log(response);
      if(response){
        notification.success({
          message: 'Orgnisation updated successfully',
        });
        // navigate(`/groups`)
        setIsModalVisible(false);
        queryClient.invalidateQueries(["groupDetails"]);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update Orgnisation details.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Organisation</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Organisation Name</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupName" value={newGroupName} onChange={(e)=>setNewGroupName(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Organisation Description</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupDescription" value={newGroupDescription} onChange={(e)=>setNewGroupDescription(e.target.value)} type="text" />
      </div>
      {/* <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} placeholder={groupAbout.groupDescription} type="file" onChange={(e)=>setNewFile(e.target.files[0])} />
      {
        newFile && <img src={URL.createObjectURL(newFile)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div> */}

      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleGroupUpdate} >Update</button>
    </div>
  )
}

export const ExitGroup=({groupAbout,currentUser})=>{
  console.log(groupAbout,"from exit")
  console.log(currentUser)
  const navigate = useNavigate()

  const exitGroupByUser=async()=>{
    try{
      const response = await makeRequest.delete(`/users/group/exitGroup/${groupAbout.groupId}/${groupAbout.adminId}/${currentUser.id}`)
      console.log(response)
      if(response){
        navigate(`/organisation`)
        notification.success({
          message: 'Group exited successfully',
        });        
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to exit the group.",
        });
      }
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to exit the group.",
      });
    }
  }

  return(
    <div>
      <h1 style={{margin:"20px"}}>Exit Organisation</h1>
      <p style={{fontSize:"0.8rem",marginBottom:"10px"}}>Are you sure you want to exit this organisation?</p>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={exitGroupByUser}>Exit organisation</button>
    </div>
  )
}