import "./navbar.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunny";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { NotificationsOutlined } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useCallback } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import SearchItem from "./SearchItem";
import { debounce } from "lodash";
import { Modal } from "antd";
import { makeRequest } from "../../axios";
import logo_symbol from "../../assets/c1.png";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { yellow } from "@mui/material/colors";
import { CloseOutlined } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import alarm from "../../assets/alarm.png";
import logoutImg from '../../assets/logout.png'
import name from "../../assets/c2.png"

const Navbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser, logout } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMessagesPage = location.pathname === "/messages";
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const queryClient = useQueryClient();


  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      try {
        const response = await makeRequest.get(
          `/users/user/searchUser/${query}`
        );
        setSearchResults(response.data.searchedData);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }, 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.length > 0) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchResults([]);
    }
  }, [searchInput]);

  const closeSearchResults = () => {
    setIsSearchResultVisible(false);
    setSearchInput(""); // Optional: Clear search input when closing
  };

  const {
    isLoading,
    isError,
    data: notifications,
  } = useQuery(["notification"], () => {
    return makeRequest
      .get(`/users/notification/getAllNotification/${currentUser.id}`)
      .then((res) => {
        setNotificationCount(res?.data?.unreadNotificationCount || 0);
        return res.data.notificationDetails;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setNotificationCount(0);
          return [];
        }
      });
  });

  const notificationMutation = useMutation(
    (id) => {
      return makeRequest.put(
        "/users/notification/updateNotificationStatus/" + id
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notification");
      },
      onError: () => {
        console.error("Failed to update notification status");
      },
    }
  );

  const handleNotification = () => {
    setIsNotificationVisible(!isNotificationVisible);
    notificationMutation.mutate(currentUser.id);
  };

  return (
    <div
      className={`navbar ${darkMode ? "dark" : "light"} ${
        isMessagesPage ? "messagePage" : ""
      }`}
    >
      <div className="left">
        <Link to="/" style={{ textDecoration: "none", height: "50px",alignItems:'center',display:'flex' }}>
          <img src={logo_symbol} style={{ width: "50px", height: "50px" }} alt="Logo" /> 
          <img src={name} className="namLogo" style={{ width: "100px", height: "30px" }} alt="Logo" /> 
        </Link>
        <div className={`search ${isSearchResultVisible ? "resultActive" : ""}`}>
          <SearchOutlinedIcon
            aria-label="Search"
            onClick={() => setIsSearchResultVisible(!isSearchResultVisible)}
          />
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchInputChange}
            onFocus={() => setIsSearchResultVisible(true)}
          />
          {isSearchResultVisible && (
            <>
              {/* <button className="closeButton" onClick={closeSearchResults}>
                
              </button> */}

              <CloseOutlined  onClick={closeSearchResults}/>
              <div className="searchResultsContainer">
                {searchResults.map((result) => (
                  <SearchItem key={result.id} friend={result} closeSearchResults={closeSearchResults} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      

      <div className="right">
      <div className="notification" onClick={handleNotification}>
          {/* <NotificationsOutlined aria-label="Notifications" /> */}
          <img src={alarm} width={"30px"}/>
          {notificationCount > 0 && <span className="badge">{notificationCount}</span>}
        </div>
        <div
          className={`notificationList ${isNotificationVisible ? "visible" : ""}`}
        >
          {notifications?.length > 0 ? (
            notifications.map((notification) => (
              <div key={notification.id} className="notificationItem">
                {notification.notificationMessage}
              </div>
            ))
          ) : (
            <p>No notifications available</p>
          )}
        </div>
        {isNotificationVisible && (
          <div className="notificationOverlay" onClick={() => setIsNotificationVisible(false)} />
        )}
      {/* {darkMode ? (
          <WbSunnyOutlinedIcon
            sx={{ color: yellow[700] }}
            aria-label="Switch to Light Mode"
            onClick={toggle}
          />
        ) : (
          <DarkModeOutlinedIcon aria-label="Switch to Dark Mode" onClick={toggle} />
        )} */}
        <button onClick={() => setIsModalVisible(true)}>
          <img src={logoutImg} width={"30px"}/>
          </button>
        <div className="user">
          <Link to={`/profile/${currentUser.UUID}`}>
            <img src={currentUser.profilePic} alt={currentUser.name} />
          </Link>
        </div>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        footer={[
          <button
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="logout"
            type="primary"
            onClick={handleLogout}
          >
            Logout
          </button>,
        ]}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default Navbar;


// import "./navbar.scss";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunny";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import { NotificationsOutlined } from "@mui/icons-material";
// import { Link, useLocation } from "react-router-dom";
// import { useContext, useState, useEffect, useCallback } from "react";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { AuthContext } from "../../context/authContext";
// import { useNavigate } from "react-router-dom";
// import SearchItem from "./SearchItem";
// import { debounce } from "lodash";
// import { Modal } from "antd";
// import { makeRequest } from "../../axios";
// import logo_symbol from "../../assets/c1.png";
// import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import { yellow } from "@mui/material/colors";
// import { CloseOutlined } from "@mui/icons-material";
// import LogoutIcon from '@mui/icons-material/Logout';
// import alarm from "../../assets/alarm.png";
// import logoutImg from '../../assets/logout.png'
// import name from "../../assets/c2.png"

// const Navbar = () => {
//   const { toggle, darkMode } = useContext(DarkModeContext);
//   const { currentUser, logout } = useContext(AuthContext);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [searchInput, setSearchInput] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const isMessagesPage = location.pathname === "/messages";
//   const [notificationCount, setNotificationCount] = useState(0);
//   const [isNotificationVisible, setIsNotificationVisible] = useState(false);
//   const queryClient = useQueryClient();

//   const handleLogout = () => {
//     logout();
//     navigate("/login");
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const fetchSearchResults = useCallback(
//     debounce(async (query) => {
//       try {
//         const response = await makeRequest.get(
//           `/users/user/searchUser/${query}`
//         );
//         setSearchResults(response.data.searchedData);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       }
//     }, 300),
//     []
//   );

//   const handleSearchInputChange = (e) => {
//     const value = e.target.value;
//     setSearchInput(value);
//     if (value.length > 0) {
//       fetchSearchResults(value);
//     } else {
//       setSearchResults([]);
//     }
//   };

//   useEffect(() => {
//     if (searchInput.length === 0) {
//       setSearchResults([]);
//     }
//   }, [searchInput]);

//   const closeSearchResults = () => {
//     setIsSearchResultVisible(false);
//     setSearchInput(""); // Optional: Clear search input when closing
//   };

//   const {
//     isLoading,
//     isError,
//     data: notifications,
//   } = useQuery(["notification"], () => {
//     return makeRequest
//       .get(`/users/notification/getAllNotification/${currentUser.id}`)
//       .then((res) => {
//         setNotificationCount(res?.data?.unreadNotificationCount || 0);
//         return res.data.notificationDetails;
//       })
//       .catch((error) => {
//         if (error.response && error.response.status === 404) {
//           setNotificationCount(0);
//           return [];
//         }
//       });
//   });

//   const notificationMutation = useMutation(
//     (id) => {
//       return makeRequest.put(
//         "/users/notification/updateNotificationStatus/" + id
//       );
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("notification");
//       },
//       onError: () => {
//         console.error("Failed to update notification status");
//       },
//     }
//   );

//   const handleNotification = () => {
//     setIsNotificationVisible(!isNotificationVisible);
//     notificationMutation.mutate(currentUser.id);
//   };

//   return (
//     <div
//       className={`navbar ${darkMode ? "dark" : "light"} ${
//         isMessagesPage ? "messagePage" : ""
//       }`}
//     >
//       <div className="left">
//         <Link to="/" style={{ textDecoration: "none", height: "50px",alignItems:'center',display:'flex' }}>
//           <img src={logo_symbol} style={{ width: "50px", height: "50px" }} alt="Logo" /> 
//           <img src={name} style={{ width: "100px", height: "30px" }} alt="Logo" /> 
//         </Link>
      
//       </div>

//       <div className="center">
//       <div className={`search ${isSearchResultVisible ? "resultActive" : ""}`}>
//           <SearchOutlinedIcon
//             aria-label="Search"
//             onClick={() => setIsSearchResultVisible(!isSearchResultVisible)}
//           />
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchInput}
//             onChange={handleSearchInputChange}
//             onFocus={() => setIsSearchResultVisible(true)}
//           />
//           {isSearchResultVisible && (
//             <>
//               {/* <button className="closeButton" onClick={closeSearchResults}>
                
//               </button> */}

//               <CloseOutlined  onClick={closeSearchResults}/>
//               <div className="searchResultsContainer">
//                 {searchResults.map((result) => (
//                   <SearchItem key={result.id} friend={result} closeSearchResults={closeSearchResults} />
//                 ))}
//               </div>
//             </>
//           )}
//         </div>
//       </div>

//       <div className="right">
//       <div className="notification" onClick={handleNotification}>
//           {/* <NotificationsOutlined aria-label="Notifications" /> */}
//           <img src={alarm} width={"30px"}/>
//           {notificationCount > 0 && <span className="badge">{notificationCount}</span>}
//         </div>
//         <div
//           className={`notificationList ${isNotificationVisible ? "visible" : ""}`}
//         >
//           {notifications?.length > 0 ? (
//             notifications.map((notification) => (
//               <div key={notification.id} className="notificationItem">
//                 {notification.notificationMessage}
//               </div>
//             ))
//           ) : (
//             <p>No notifications available</p>
//           )}
//         </div>
//         {isNotificationVisible && (
//           <div className="notificationOverlay" onClick={() => setIsNotificationVisible(false)} />
//         )}
//       {/* {darkMode ? (
//           <WbSunnyOutlinedIcon
//             sx={{ color: yellow[700] }}
//             aria-label="Switch to Light Mode"
//             onClick={toggle}
//           />
//         ) : (
//           <DarkModeOutlinedIcon aria-label="Switch to Dark Mode" onClick={toggle} />
//         )} */}
//         <button onClick={() => setIsModalVisible(true)}>
//           <img src={logoutImg} width={"30px"}/>
//           </button>
//         <div className="user">
//           <Link to={`/profile/${currentUser.id}`}>
//             <img src={currentUser.profilePic} alt={currentUser.name} />
//           </Link>
//         </div>
//       </div>
//       <Modal
//         title="Logout Confirmation"
//         visible={isModalVisible}
//         onOk={handleLogout}
//         onCancel={handleCancel}
//         footer={[
//           <button
//             style={{
//               backgroundColor: "#e4002b",
//               color: "white",
//               border: "none",
//               borderRadius: "5px",
//               width: "4rem",
//               height: "2rem",
//               fontWeight: "600",
//               fontSize: "0.9rem",
//             }}
//             key="logout"
//             type="primary"
//             onClick={handleLogout}
//           >
//             Logout
//           </button>,
//         ]}
//       >
//         <p>Are you sure you want to logout?</p>
//       </Modal>
//     </div>
//   );
// };

// export default Navbar;
