import React from 'react'
import './aboutComp.scss';
import moment from "moment";

function AboutComp({data}) {
    console.log("data from about cmop:",data)
  return (
    <div className='about'>
      <h1>About</h1>
      <hr/>
      <div className="user-details">
        <div className="item">
            <h4>NAME</h4>
            <p>{data.name}</p>
        </div>
        <div className="item">
            <h4>DESCRIPTION</h4>
            <p>{data.description}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>HOST</h4>
            <p>{data.adminUserFirstName} {data.adminUserLastName}</p>
        </div>
        <div className="item">
            <h4>LOCATION</h4>
            <p>{data.address}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>COMPETITION DATE</h4>
            <p>{data. startDate} TO {data.endDate}</p>
        </div>
        <div className="item">
            <h4> COMPETITION TIME</h4>
            <p>{data.startTime} TO {data.endTime}</p>
        </div>
      </div>
    </div>
  )
}

export default AboutComp
