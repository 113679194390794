import "./adminDashboard.scss";
import UsersLineChart from "../../components/admin/analytics/usersLineChart";
import UserAndPostsData from "../../components/admin/analytics/UsersAndPostsData";
import PieChartComp from "../../components/admin/analytics/pieChart/PieChartComp";
import { makeRequest } from "../../axios";
import axios from "axios";
import { useState, createContext, useEffect} from "react";
const apiUrl = process.env.REACT_APP_API_URL;

export const DistrictContext = createContext();
const AdminDashboard = () => {
  const [districts, setDistricts] = useState([]);
  const [districtWiseData, setDistrictWiseData] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(apiUrl + "/users/district/getAllDistricts");
      console.log(response)
      setDistricts(response.data.getAllDistrictData);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchData = async () => {
    try {
      const response = await makeRequest.get('/admin/stats');
      setDistrictWiseData(response.data);
      console.log(response.data);
      // Initialize with 'All' data on load
      setFilteredData([
        { name: 'Posts', value: response.data.All.totalPosts },
        { name: 'Users', value: response.data.All.totalUsers },
        { name: 'Disputes', value: response.data.All.totalDisputes },
        { name: 'Groups', value: response.data.All.totalGroups },
        { name: 'Pages', value: response.data.All.totalPages },
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    
    fetchData();
  }, []);


  useEffect(() => {   

    fetchDistricts();
  }, []);

    return (
      // <div className="adminDashboard" style={{backgroundColor:"#fff0f3"}}>
      // <h2>Dashboard</h2>      
      // <PieChartComp/>
      // <UserAndPostsData/>
      // {/* <UsersLineChart/> */}
      // </div>
      <DistrictContext.Provider value={{ districts }}>
      <div className="adminDashboard">
      <h2>Dashboard</h2>
      <PieChartComp/>
      <UserAndPostsData/>
      <div className="analyticsContainer">
      {/* <UsersLineChart/> */}
      
      </div>
      </div>
      </DistrictContext.Provider>
    )
  }
  
  export default AdminDashboard;