import React, { useContext, useEffect, useState } from "react";
import "./createEvent.scss";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { notification,message } from "antd";
import Image from "../../assets/img.png";
import AWS from "aws-sdk";
import { Navigate, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";


const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function CreateEvent() {
  const [eventCat, setEventCat] = useState([]);
  const { currentUser } = useContext(AuthContext);
  console.log(currentUser);
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const [file, setFile] = useState(null);
  const [districts, setDistricts] = useState([]);

  const S3_BUCKET = "streesakti";

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    eventName: "",
    eventDesc: "",
    eventStartDate: "",
    eventStartTime: "",
    district:"",
    eventEndDate: "",
    eventEndTime: "",
    eventLocation: "",
    eventImage: "",
    eventCategory: "",
    postOwnerType: "",
  });

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();  

  const handleInput = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  

  const getEventCategory = async () => {
    try {
      const response = await makeRequest.get(`/sup-admin/get`);
      console.log(response);
      if (response.status === 200) {
        setEventCat(response.data.eventCatgDetails);
      } else {
        setEventCat([]);
      }
    } catch (err) {
      console.log(err);
      setEventCat([]);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await makeRequest.get("/users/district/getAllDistricts");
      setDistricts(response.data.getAllDistrictData);
    } catch (err) {
      console.error(err);
    }
  };
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD
  const currentTimeString = currentDate.toTimeString().split(' ')[0].slice(0, 5);
  const minTime = (inputs.eventStartDate === currentDateString) ? currentTimeString : '00:00';

  useEffect(() => {
    fetchDistricts();
    getEventCategory();
  }, []);

  const createEvents = async () => {
    if (inputs.eventStartDate === currentDateString && inputs.eventStartTime < currentTimeString) {
      notification.error({
        message: "You cannot select a past time.",
      });
      return;
    }
    if (
      !inputs.eventName || 
      !inputs.eventCategory ||
      !inputs.eventStartDate ||
      !inputs.eventEndDate ||
      !inputs.eventStartTime ||
      !inputs.eventEndTime
    ) {
      notification.error({
        message: "Please fill all required fields",
      });
      return;
    }
    try {
      setIsCreatingEvent(true);
      let fileToUpload = file;
      let postImageUrl = null;
      if (file) {

        console.log("file is not null");
        if (file.type.includes("image")) {
          const imageOptions = {
            maxSizeMB: 0.1, // 150KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(file, imageOptions);

          // Convert the compressed Blob back to a File
          fileToUpload = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: file.lastModified,
          });
        } else {
          message.error("Only images are allowed!");
          return;
        }
      }


      console.log('File is null');
      let formdata = new FormData();
      formdata.append("eventName", inputs.eventName);
      formdata.append("eventDescription", inputs.eventDesc);
      formdata.append("startDate", inputs.eventStartDate);
      formdata.append("startTime", inputs.eventStartTime);
      formdata.append("endDate", inputs.eventEndDate);
      formdata.append("endTime", inputs.eventEndTime);
      formdata.append("eventAddress", inputs.eventLocation);
      formdata.append("eventPostType", "image");
      formdata.append("eventVideoThumbnailUrl", null);
      formdata.append("postOwnerType", inputs.postOwnerType);
      // formdata.append("imageUrl", fileToUpload);

      if (fileToUpload) {
        formdata.append("eventImageUrl", fileToUpload); // Append the file directly
      } 
      console.log("this is file", fileToUpload);
      console.log("this is file  from formdata", formdata.get("eventImageUrl"));
      console.log("this is 1st file", file);
      const response = await makeRequest.post(
        `/users/event/create/${currentUser.id}/${inputs.district}?eventCatgId=${inputs.eventCategory}`,
        formdata,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // console.log(response)
      if (response.data.status) {
        notification.success({
          message: "Event Created",
          description: "Your Event has been successfully created.",
        });
        navigate("/events");       
      }      
    } catch (error) {
      console.error("Error creating Event:", error);
      notification.error({
        message: "Submission Error",
        description: `${error.response.data.message}`,
      });
    } finally {
      setIsCreatingEvent(false);
    }
  }



  return (
    <div className="createEvents">
      <h1>Host an Event</h1>
      <div className="formGroups">
        <select
          className="selInp"
          name="eventCategory"
          onChange={handleInput}
          value={inputs.eventCategory}
        >
          <option value="">Select Event Category</option>
          {eventCat &&
            eventCat.map((item, index) => (
              <option key={item.catId} value={item.catId}>
                {item.catName}
              </option>
            ))}
        </select>
        <select
          className="selInp"
          value={inputs.postOwnerType}
          name="postOwnerType"
          onChange={handleInput}
        >
          <option value="">Who can see your events</option>
          <option value="PUBLIC_EVENT">PUBLIC</option>
          <option value="PRIVATE_EVENT">PRIVATE</option>
        </select>
        <input
          className="inp"
          name="eventName"
          value={inputs.eventName}
          onChange={handleInput}
          type="text"
          placeholder="Event Name"
        />
        <input
          className="inp"
          name="eventDesc"
          value={inputs.eventDesc}
          onChange={handleInput}
          type="text"
          placeholder="What is this Event about"
        />
        <div className="stDate">
          <div className="sDate">
            <label className="labelForE">Start Date</label>
            <input
              className="inp"
              name="eventStartDate"
              value={inputs.eventStartDate}
              onChange={handleInput}
              type="date"
              placeholder="Date"
              min={currentDateString}
            />
          </div>
          <div className="sDate">
            <label className="labelForE">Start Time</label>
            <input
              className="inp"
              name="eventStartTime"
              value={inputs.eventStartTime}
              onChange={handleInput}
              type="time"
              placeholder="Time"
              min={minTime}
            />
          </div>
        </div>
        <div className="stDate">
          <div className="sDate">
            <label className="labelForE">End Date</label>
            <input
              className="inp"
              name="eventEndDate"
              value={inputs.eventEndDate}
              onChange={handleInput}
              type="date"
              placeholder="Date"
              min={inputs.eventStartDate}
            />
          </div>
          <div className="sDate">
            <label className="labelForE">End Time</label>
            <input
              className="inp"
              name="eventEndTime"
              value={inputs.eventEndTime}
              onChange={handleInput}
              type="time"
              placeholder="Time"
            />
          </div>
        </div>
        <div className="stDate">
          <div className="sDate">
            <label
              className="labelForE"
              htmlFor="evePic"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Image} alt="image" />
              Add Image
            </label>
            <input
              className="inpFile"
              name="eventImage"
              id="evePic"
              style={{ display: "none" }}
              value={inputs.eventImage}
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              placeholder="Event Image"
            />
          </div>
          <div className="sDate">
            {file && (
              <img
                src={URL.createObjectURL(file)}
                alt="file"
                width="70px"
                height="50px"
              />
            )}
          </div>
        </div>
        <select className="selInp"
                  name="district"
                  value={inputs.district}
                  onChange={handleInput}
                >
                  <option value="">Select District</option>
                  {districts && districts
                  .filter(item => item.districtId !== 1) 
                  .map((district) => (
                    <option key={district.districtId} value={district.districtId}>
                      {district.districtName}
                    </option>
                  ))}
                </select>
        <input
          className="inp"
          name="eventLocation"
          value={inputs.eventLocation}
          onChange={handleInput}
          type="text"
          placeholder="Address of the event"
        />
        <button onClick={createEvents}>
          {isCreatingEvent ? "Creating  event.." : "Create Event"}
        </button>
      </div>
    </div>
  );
}

export default CreateEvent;
